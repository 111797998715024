.header-ub {
  display: flex;
  justify-content: center;
  font-weight: bolder;
  font-size: 3em;
  color: white;
  background-color: rgba(24, 24, 24, 0.822);
  border-radius: 5px;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  text-align: center;
}

.header {
  height: 95vh;
  width: 100%;
  object-fit: contain;
}

.header-image {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/about/ubuntu-about.png");
  background-size: cover;
  background-position-x: center;
}

/* ------------------ [ Program Description]  --------------------------*/

.paragraph {
  font-size: 18px;
}

.prog-icon-container {
  justify-content: center;
  text-align: center;
  margin-top: 3em;
  color: white;
}

.about-section-container {
  justify-content: center;
  margin-top: 3em;
  align-items: center;
}

.about-section-wrapper {
  height: fit-content;
}

.trio-logo {
  text-align: center;
}

/*--------------------------  [ Staff ] --------------------------*/

.staff-card {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: " role role " "personicon person" "emailicon email" "phoneicon phone";
  row-gap: 7px;
  justify-content: center;
  margin-top: 3em;
}

.staff-card-role {
  grid-area: role;
  text-align: center;
}
.staff-card-person {
  grid-area: person;
  font-weight: bold;
}
.staff-card-person-icon {
  grid-area: personicon;
}

.staff-card-email {
  grid-area: email;
}
.staff-card-email-icon {
  grid-area: emailicon;
  color: red;
}
.staff-card-phone {
  grid-area: phone;
}
.staff-card-phone-icon {
  grid-area: phoneicon;
  color: red;
}

.staff-card-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1em 0 0;
  /* background-color: aqua; */
}

.staff-card-item {
  display: flex;
  align-content: center;
  /* background-color: blanchedalmond; */
}

/*-------------------------- [ Faqs ] --------------------------*/

.container-faqs {
  justify-content: center;
  margin-top: 3em;
  margin-left: 10%;
  margin-right: 10%;
  font-family: "Nunito";
  align-items: center;
}

/*  page section layout with question */

.page-section-layout-question {
  display: grid;
  grid-template-columns: 2fr 8fr 3fr;
  grid-template-rows: auto;
  grid-template-areas: "menus displays question";
  overflow-y: auto;
  height: 85vh;
  background-color: rgb(255, 255, 255);
}
.page-section-menu {
  grid-area: menus;
  padding: 3.5em 0 0 0;
  background-color: whitesmoke;
}

.page-section-display {
  grid-area: displays;
  align-items: center;
}

.page-section-menu-item {
  padding: 0.5em;
}

.page-section-question {
  grid-area: question;
  height: 100%;
  width: 100%;
  background-color: whitesmoke;
}

.page-section-menu-item:hover {
  cursor: pointer;
  background-color: rgb(255, 0, 0);
}
.question-iframe {
  width: 100%;
  height: 100%;
}
iframe {
  overflow: scroll;
}
@media only screen and (max-width: 1250px) {
  .page-section-layout-question {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto 100vh;
    grid-template-areas: "menus" "displays" "question";
    height: fit-content;
    padding: 5em 0;
  }
  .page-section-menu {
    padding: 0 0 0 0;
  }

  .page-section-display {
    grid-area: displays;
    width: 100%;
    align-items: flex-start;
    padding: 2em;
  }

  .page-section-question {
    grid-area: question;
    height: 100%;
    padding: 2em;
  }
}
