.padlet-embed-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #f4f4f4;
}

.padlet-frame {
  width: 100%;
  height: 100vh;
  display: block;
  padding: 5vh 0 0 0;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .padlet-frame {
    height: 100vh;
    padding: 7vh 0 0 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1250px) {
  .padlet-frame {
    padding: 7vh 0 0 0;
    height: 100vh;
  }
}
