.opening-div {
  background-color: rgb(0, 0, 0);
  margin: 10em 0 0 0;
}

.page-section-layout {
  display: grid;
  grid-template-columns: 2fr 10fr;
  grid-template-rows: auto;
  grid-template-areas: "menus displays";
  overflow-y: auto;
  height: 90vh;
  background-color: rgb(255, 255, 255);
}
.page-section-menu {
  grid-area: menus;
  padding: 3.5em 0 0 0;
  background-color: whitesmoke;
}

.page-section-display {
  grid-area: displays;
  align-items: center;
}

.page-section-menu-item {
  padding: 0.5em;
}
.page-section-menu-item:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
}

@media only screen and (max-width: 1250px) {
  .page-section-layout {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas: "menus" "displays";
    height: fit-content;
    padding: 5em 0;
  }
  .page-section-menu {
    padding: 0 0 0 0;
  }

  .page-section-display {
    grid-area: displays;
    width: 100%;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .opening-div {
    margin: 7em 0 0 0;
  }
}

.section-overview-grid {
  display: grid;
  grid-template-areas:
    "tabs tabs "
    "tabcontent carousel";
  grid-template-columns: 5fr 8fr;
  grid-template-rows: auto;
}

.section-overview-tabs {
  grid-area: tabs;
  display: flex;
  align-content: center;
  justify-content: center;
  /* background-color: yellow; */
}

.section-overview-tabcontent {
  grid-area: tabcontent;
}

.section-overview-carousel {
  grid-area: carousel;
}

@media only screen and (max-width: 1025px) {
  .section-overview-grid {
    width: 100%;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "tabs"
      "tabcontent"
      "carousel";
    background-color: rgb(255, 255, 255);
  }
  .page-section-menu-item {
    padding: 0.5em;
    text-align: center;
  }
}

