.div-content {
  margin-top: auto;
  width: 100%;
}

.App {
  font-family: "Source Serif Pro", serif;
  width: 100%;
}

/*--------------------------  [  SECTION GRID LAYOUT ]  --------------------------*/

.page-section-layout {
  display: grid;
  grid-template-columns: 2fr 10fr;
  grid-template-rows: auto;
  grid-template-areas: "menus displays";
  overflow-y: auto;
  height: 80vh;
  background-color: rgb(255, 255, 255);
}
.page-section-menu {
  grid-area: menus;
  padding: 3.5em 0 0 0;
  background-color: whitesmoke;
}

.page-section-display {
  grid-area: displays;
  align-items: center;
}

.page-section-menu-item {
  padding: 0.5em;
}
.page-section-menu-item:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
}

@media only screen and (max-width: 1250px) {
  .page-section-layout {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas: "menus" "displays";
    height: fit-content;
    padding: 5em 0;
  }
  .page-section-menu {
    padding: 0 0 0 0;
  }

  .page-section-display {
    grid-area: displays;
    width: 100%;
    align-items: flex-start;
  }
}


