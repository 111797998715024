.icon-nav-grid {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 3em;
  display: grid;
  grid-template-columns: repeat(12, 10.5%);
  grid-template-rows: auto;
  background-color: rgb(255, 255, 255);
  margin: 7em 0 0 0;
  width: auto;
  z-index: 5;
}

.icon-card {
  display: grid;
  grid-template-columns: 1fr auto 0.25fr auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: ". icon . iconName .";
  align-content: center;
  /* border: 2px solid black; */
}

.icon-area {
  grid-area: icon;
  color: red;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: antiquewhite; */
  font-size: 1.25em;
  cursor: pointer;
}
.icon-area:hover {
  color: red;
}
.name-area {
  grid-area: iconName;

  /* background-color: rgb(172, 143, 108); */
}

@media only screen and (max-width: 1200px) and (min-width: 750px) {
  .icon-nav-grid {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0%;
    right: 0;
    height: 2em;
    display: grid;
    grid-template-columns: repeat(10, 10%);
    grid-template-rows: auto;
    background-color: rgb(255, 255, 255);
    width: auto;
    z-index: 5;
    padding-left: 10%;
  }
  .name-area {
    color: white;
  }
}

@media only screen and (max-width: 750px) {
  .icon-nav-grid {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 3em;
    display: grid;
    grid-template-columns: repeat(10, 11.25%);
    grid-template-rows: auto;
    background-color: rgb(255, 255, 255);
    margin: 4em 0 0 0;
    width: auto;
    z-index: 5;
    padding-left: 5%;
  }
  .name-area {
    color: white;
  }
}
