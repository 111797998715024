/* ---------------  [ELT CARDS: CONTAINER, CARD STYLE,  IMAGE, NAME BUTTON ]  ----------------------------- */
.elt-image-container {
  display: flex;
  justify-content: center;
  height: 225px;
  align-self: center;
  grid-area: image;
}

.elt-image {
  width: 225px;
  object-fit: cover;
  border-radius: 50%;
  align-items: center;
}

.elt-card-name {
  text-align: center;
  font-size: x-large;
  padding: 2em;
  font-weight: bold;
}

.elt-card-intro {
  grid-area: intro;
  align-self: center;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  row-gap: 12px;
  grid-template-areas:
    "intro"
    "tags";
}

.elt-card-container {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". card .";
  height: 40vh;
  /* background: yellow; */
}

.elt-card {
  grid-area: card;
  display: grid;
  grid-template-columns: auto 1fr 4fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "image . intro . extras";
  /* background-color: red; */
}

@media only screen and (max-width: 1250px) {
  .elt-card-container {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "card";
    height: fit-content;
    padding: 2em;
  }

  .elt-card {
    grid-area: card;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    row-gap: 2em;
    grid-template-areas:
      "image"
      "intro"
      "extras";
    /* background-color: red; */
  }
}
