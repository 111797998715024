.section-icon {
  color: white;
  cursor: pointer;
  justify-content: center;
  align-self: center;
}

.section-icon:hover {
  color: red;
}

.section-container {
  height: 35vh;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". message insta youtube face .";
  background-color: rgba(255, 255, 255, 0.068);
}

.section-message {
  grid-area: message;
  padding: 1em;
  justify-content: center;
  align-self: center;
  color: white;
}

.section-icon-insta {
  grid-area: insta;
}

.section-icon-youtube {
  grid-area: youtube;
}

.section-icon-face {
  grid-area: face;
}

@media only screen and (max-width: 1200px) {
  .section-container {
    height: 50vh;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
    grid-template-rows: 30vh 5vh;
    grid-template-areas:
      ". message message message ."
      ". insta youtube face .";
  }
  .section-message {
    grid-area: message;
    text-align: center;
  }
  .section-icon {
    text-align: center;
  }
} ;
