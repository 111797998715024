/*---------------------------[ Student Testimonial 1] -----------------------------*/

.testimonial-section-wrapper {
  background-color: #dbe4ee3b;
  padding-top: 3em;
  padding-bottom: 3em;
}
.testimonial-image-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 50vh;
}

.testimonial-image {
  height: 100%;
  object-fit: contain;
}

.testimonial-image:hover {
  box-shadow: 0px 0px 10px 3px #dbe4ee;
}

.testimonial-title {
  color: #0d1b2a;
}
.testimonial-quote {
  font-size: larger;
}
.testimonial-container {
  display: flex;
  flex-wrap: wrap;
  color: #0d1b2a;
  text-align: center;
  font-family: "Nunito";
  justify-content: center;
}

.testimonial-row-sub {
  justify-content: center;
}

/*---------------------------[ Summer TITLE section] -----------------------------*/
#summer-title {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  margin-left: 100px;
  margin-right: 100px;
  font-family: "Nunito";
  color: #30323d;
}
#summer-text-section {
  line-height: 400px;
  height: 400px;
  width: 100% !important;
  text-align: center;
  color: white;
  background-color: #dbe4ee;
}
/*---------------------------[ Summer Container] -----------------------------*/
#summer-programming-container {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 100px;
}
/*---------------------------[ image/video ] -----------------------------*/

#image-top-row {
  height: auto;
}
#image-bottom-row {
  height: auto;
}
#summer-content-image-bottom {
  height: 250px;
  width: 250px;
  background-position: center;
  background-size: cover;
  margin-top: 50px;
  box-shadow: 3px 3px 10px 1px black;
}
#summer-content-image-top {
  height: 250px;
  width: 250px;
  background-position: center;
  background-size: cover;
  box-shadow: 3px 3px 10px 1px black;
}

.summer-content-video {
  height: 550px;
  width: 750px;
  box-shadow: 3px 3px 10px 1px black;
}

/*---------------------------[ Summer sliders] -----------------------------*/
#summer-slider-title {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0;
}
.testimonial-row-main {
  margin-bottom: 3em;
  display: flex;
  flex-wrap: wrap;
}
.testimonial-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 3em;
}

/*---------------------------[ Featured Event 1] -----------------------------*/

.ft-section-wrapper {
  background-color: #dbe4ee;
  padding-top: 3em;
  padding-bottom: 3em;
}


