.student-header {
  height: 77.5vh;
  background-image: url("../../assets/students/elt-team.JPG");
  background-position-x: center;
  background-position-y: 35%;

  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.student-header-grid {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 2fr 1fr;
  grid-template-rows: 7vh 25%;
  grid-template-areas:
    ". empty empty empty . "
    ". title title title  ."
    ". contents contents contents  .";
  height: inherit;
}
.student-header-grid-empty {
  grid-area: empty;
}
.student-header-grid-title {
  grid-area: title;
  font-size: xx-large;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.685);
  align-self: center;
  justify-self: center;
  padding: 0.5em 2em;
  word-spacing: 7px;
  text-align: center;
  font-weight: bold;
}

.student-header-grid-content {
  grid-area: contents;
}
@media only screen and (max-width: 512px) {
  .student-header {
    background-size: contain;
    height: 75vh;
    background-position: bottom;
  }

  .student-header-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 4fr 2fr 1fr;
    grid-template-rows: 17.5vh 20vh;
    grid-template-areas:
      ". empty empty empty . "
      ". title title title  ."
      ". contents contents contents  .";
    height: inherit;
  }

  .student-header-grid-title {
    padding: 0;
    font-size: x-large;
    word-spacing: normal;
    text-align: center;
  }
}

@media only screen and (max-width: 1250px) and( min-width:512px) {
  .student-header {
    background-size: contain;
  }

  .student-header-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 4fr 2fr 1fr;
    grid-template-rows: 12.5vh 20vh;
    grid-template-areas:
      ". empty empty empty . "
      ". title title title  ."
      ". contents contents contents  .";
    height: inherit;
  }

  .student-header-grid-title {
    padding: 0;
    font-size: xx-large;
    letter-spacing: 5px;
    word-spacing: normal;
    text-align: center;
  }
}

/* ------------------ [Padlet ] ----------------------- */

.padlet-grid-1 {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 3fr 1fr;
  grid-template-rows: 1fr 5fr;
  row-gap: 2em;
  grid-template-areas: ". intro intro intro . " " . a b c .";
  padding: 2em;
  margin: 2em;
}

.padlet-header {
  grid-area: intro;
  color: white;
  text-align: center;
}

.padlet-a {
  grid-area: a;
  /* background-color: rgb(123, 255, 0); */
}

.padlet-b {
  grid-area: b;
  /* background-color: rgb(217, 255, 0); */
}
.padlet-c {
  grid-area: c;
  /* background-color: rgb(111, 0, 255); */
}

.padlet-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 480px;
  height: 352px;
  max-width: 100%;
  padding: 1em;
}

.iframe-style {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1000px) {
  .padlet-grid-1 {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto;
    row-gap: 2em;
    grid-template-areas:
      ". intro . "
      " . a . "
      " . b . "
      " . c .";
    padding: 2em;
  }
  .padlet-wrapper {
    width: 100%;
    padding: 0;
  }
} ;
