.header-video-grid {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-template-rows: auto;
  grid-template-areas: "updates video";
  height: 75vh;
  background-color: rgb(255, 0, 0)
}

@media only screen and (max-width: 1275px) {
  .header-video-grid {
    padding: 5em 0 0em 0;

    grid-template-columns: auto;
    grid-template-rows: auto 100vh;
    grid-template-areas: "updates" "video";
    height: fit-content;
  }
}

.vg-video {
  grid-area: video;
  background-color: rgb(0, 0, 0);
  border-top: 5px solid white;
}

.vg-updates {
  grid-area: updates;
  display: grid;
  background-color: black;
  color: white;
  grid-template-columns: auto;
  grid-template-rows: 4fr 4fr;
  row-gap: 3px;
  grid-template-areas:
    "update1 "
    "update2";
  /* "update3"; */

  background-size: cover;
  background-color: rgba(255, 0, 0, 0.5);
  background-blend-mode: overlay;
  height: 100%;
}

.vg-update-1 {
  grid-area: update1;
}

.vg-update-2 {
  grid-area: update2;
}

.vg-update-3 {
  grid-area: update3;
}
/*  */

.vg-update-card {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 20px auto auto auto;
  grid-template-areas: ". ." "cardTitle externalLink" "cardSummary cardSummary" "cardHashtags cardHashtags";
  row-gap: 1px;
  padding: 4em;
  height: 100%;
  border-top: 5px solid whitesmoke;
}

.vg-update-card-title {
  grid-area: cardTitle;
  font-weight: bold;
  color: white;
}

.vg-update-card-summary {
  grid-area: cardSummary;
}
.vg-update-card-hashtags {
  grid-area: cardHashtags;
  font-style: italic;
  font-weight: 100;
  font-size: 14px;
}

.vg-update-card-external {
  grid-area: externalLink;
  display: flex;
  justify-content: flex-end;
  /* background-color: red; */
}
