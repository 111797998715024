/*-------------------------------------------------------------------------------*/
.resource-banner-wrapper {
  display: inline-block;
}

.resource-banner {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  object-fit: cover;
}
/* -------------------------------------------------------------------------------*/
.resource-title {
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  padding: 2em 0em 0em;
  font-weight: 700;
}

.accordion-toggle {
  justify-content: center;
  padding: 0.5em 2em;
  width: 100%;
  background-color: inherit;
  border-style: none;
  border-radius: 1em;
}

.accordion-toggle:hover {
  background-color: rgba(255, 0, 0, 0.274);
  /* background-color: red; */
  border-style: none;
  color: white;
}

.resource-section-subheading {
  text-align: center;
  display: inline;
  font-size: 16px;
}

.resource-container {
  padding: 1em 2em 1em;
}

.resource-column {
  background-color: white;
  margin: 2em;
  display: flexbox;
  justify-content: center;
}

.resource-card-wrapper {
  display: block;
  justify-content: center;
}

.resource-card-title {
  padding: 0.5em 0em;
  text-align: center;
}

.resource-card-image {
  object-fit: contain;
  height: 3em;
}

.section-separator {
  text-align: center;
  padding: 3%;
}

.resource-section-wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  /* background-image: url("../../../assets/resources/math/spiral.jfif");
  background-position: center;
  background-size: cover; */
  padding: 2%;
}

.resource-card {
  width: 8em;
  height: 8em;
  margin: 2%;
  justify-content: center;
}

.resource-header {
  justify-items: center;
  height: 5%;
  width: 40%;
  display: flex;
  justify-content: center;
}

/*---------------------- CSS Styles for Update Sections --------------*/

.update-header-wrapper {
  height: fit-content;
  padding: 2em 0em 2em;
  background-color: #0d1b2a;
}
.update-header {
  display: flex;
  object-fit: contain;
  color: #e5e1c0;
  font-size: xx-large;
  font-weight: 700;
  justify-content: center;
  margin-left: 2%;
  margin-right: 2%;
  align-items: center;
  text-align: center;
}

.update-title {
  text-align: center;
  font-size: 18px;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 2em 0em;
}
.update-column{
  justify-content: center;
  align-content: center;
  color: white;
  margin-right:2em;
  margin-left:2em;
  padding: 0em 0em 2em;
  font-size: 18px;

}

.update-container {
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  justify-content: center;
}

.update-row-info {
  justify-content: center;
  font-size: 16px;
  text-align:center;
  padding: 2em 2em 0em;
}

.update-row {
  /* border-style: double;
  border-color: white; */
  background-color:inherit;
}





/* ------------------ [Title Section]  --------------------------*/

.title{
  display: flex;
  object-fit: contain;
  font-family: "Nunito";
  color: black;
  font-size: xx-large;
  font-weight: 700;
  justify-content: center;
  margin-left: 2%;
  margin-right: 2%;
  align-items: center;
  text-align: center;
}

