.menuItemContainerCSS {
  justify-content: center;
  padding: 1em 0em 1em;
}

.subMenuItemContainerCSS {
  display: flex;
  justify-content: center;
  padding: 0.5em 0em 0em;
}

.subLinkCSS {
  font-size: 16px;
  padding: 0em 1em 0em;
  color: rgb(255, 255, 255, 0.8);
}
.subLinkCSS:hover {
  color: black;
}

.navbar-grid {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 7em;
  z-index: 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr 2fr 2fr;
  grid-template-rows: auto;
  grid-template-areas: ".  items  . ub  . logos";
  background-color: rgb(0, 0, 0);
  align-items: center;
  border-bottom: 5px solid red;
}

.navbar-logos {
  grid-area: logos;
}

.logo-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  grid-template-areas: " trio lbcc";
  align-items: center;
}

.nav-logo-lbcc {
  grid-area: lbcc;
  display: flex;
  justify-content: center;
}
.nav-logo-ub {
  grid-area: ub;
  text-align: flex-start;
}
.nav-logo-trio {
  grid-area: trio;
  display: flex;
  justify-content: center;
}

.navbar-items {
  grid-area: items;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-item-style {
  color: white;
  padding: 0em 1em;
  font-weight: bold;
}
.navbar-item-style:hover {
  color: red;
}
.navbar-social {
  grid-area: social;
  /* background-color: rgb(47, 54, 54); */
}

.navbar-quicklinks {
  grid-area: quicklinks;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "calendar links resources join";
}

.quicklinks-item-grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "item"
    "name";
  justify-content: center;
}

.quiclink-item {
  grid-area: item;
  text-align: center;
  color: red;
}
.quiclink-name {
  grid-area: name;
  color: white;
  font-size: 12px;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .navbar-grid {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4em;
    z-index: 5;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: " . ub items ";
    background-color: rgb(0, 0, 0);
    align-items: center;
    border-bottom: 5px solid red;
  }
}
