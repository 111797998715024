.announcement-header-grid {
  display: grid;
  grid-template-columns: 1.75fr 7fr 2fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: ". header header ." ". content . ." ". icons . .";
}
.announcement-header-grid-1 {
  display: grid;
  grid-template-columns: 1.75fr 7fr 2fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: ". header header ." ". content content ." ". icons . .";
}

.announcement-header {
  grid-area: header;
  color: rgb(255, 255, 255);
  align-self: flex-end;
  font-weight: bold;
  /* background-color: beige; */
}
.announcement-content {
  grid-area: content;
  color: rgb(255, 255, 255);
  font-size: 19px;
  align-self: center;
  /* background-color: rgb(160, 160, 34); */
}

.announcement-icons {
  grid-area: icons;
  /* background-color: aquamarine; */
  /* align-self: flex-start; */
}

.image-content-container {
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-template-rows: auto;
  grid-template-areas: "text images ";
  align-self: center;
}

.announcement-text {
  grid-area: text;
  background-color: rgba(127, 255, 212, 0.452);
}

.announcement-image-container {
  display: flex;
  grid-area: images;
  color: white;
  /* background-color: aqua; */
  justify-content: flex-end;
}

.announcement-image {
  display: flex;
  height: 350px;
}

.icon-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "icon1 icon2 icon3 ";
}

.icon-button {
  background-color: red;
  color: white;
  width: 85%;
  height: auto;
  padding: 0.5em;
  text-align: center;
  background-image: linear-gradient(
    to right,
    rgb(255, 0, 0) 15%,
    rgb(215, 0, 0) 75%,
    rgb(185, 0, 0) 100%
  );
  border-radius: 2.5px;
  border: none;
}
.icon-button::hover {
  text-decoration: none;
}

.icon-card {
  display: grid;
  grid-template-columns: 1fr auto 0.25fr auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". icon . iconName .";
  align-content: center;
  /* border: 2px solid black; */
}

.icon-area {
  grid-area: icon;
  color: red;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: antiquewhite; */
  font-size: 1.25em;
  cursor: pointer;
}
.icon-area:hover {
  color: red;
}
.name-area {
  grid-area: iconName;

  /* background-color: rgb(172, 143, 108); */
}

@media screen and (max-width: 1500px) {
  .announcement-header-grid {
    display: grid;

    grid-template-columns: 1fr 5fr 2fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: ". header header ." ". content content ." ". icons icons .";
  }
  .announcement-header {
    font-size: 20px;
  }
  .announcement-content {
    font-size: 15px;
  }
  .icon-button {
    display: flex;
    width: 50%;
    align-content: center;
    justify-content: center;
    padding: 0.5em;
  }

  .icon-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    row-gap: 12px;
    grid-template-areas: "icon1 icon2 icon3";
  }

  .icon-card {
    display: grid;
    grid-template-columns: 1fr auto 0.25fr auto 1fr;
    grid-template-rows: auto;
    grid-template-areas: ". icon . . .";
    align-content: center;
    /* border: 2px solid black; */
  }
}
