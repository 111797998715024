.footer-titles {
  font-size: 20px;
}
#footer-body {
  border-top: 2px solid #f2f2f2;
}
#footer-row-titles {
  margin: 60px 0 !important;
}
.footer-lists {
  margin-top: 10px !important;
  padding-left: 0;
  list-style-type: none;
}
#footer-address {
  list-style-type: none;
  float: left;
}
.footer-links {
  text-decoration: none !important;
}
#footer-quick-links {
  list-style-type: none;
}
.footer-lists-contact-info {
  margin-top: 10px;
}
#footer-form {
  width: 250px !important;
  margin-left: 0 !important;
}
