/* ------------------ [  TABS CONTAINERS ]  ----------------------- */

.tab-button-selected-fixed {
  background-color: rgb(255, 0, 0);
  font-size: 16px;
  color: white;
  outline: none;
  border: 3px solid rgb(255, 255, 255);
}
.tab-button-selected-fixed:focus {
  outline: none;
}

.tab-title-fixed {
  font-weight: bold;
}

.tab-button-not-selected-fixed {
  background-color: white;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 16px;
  outline: none;
  padding: 1em;
}

.tab-button-not-selected-fixed:hover {
  background-color: red;
  color: white;
  outline: none;
  border: none;
}

/* ----------------------- [  TABS CONTAINERS ]  ----------------------- */

.tab-container {
  height: inherit;
  padding: 1em;
  /* background-color: aquamarine; */
}
.tab-sub-heading {
  padding: 2em 0 0 0;
}

@media only screen and (max-width: 1025px) {
}